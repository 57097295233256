<!--
  フォーム自動生成ダイアログ 
  @return {Object} { targets.key: targets.value}
-->
<template>
  <v-dialog
    v-model="showDialog"
    persistent
    :max-width="maxWidth ? maxWidth : '35vw'"
  >
    <v-card>
      <v-card-title class="justify-center white--text next">{{
        title
      }}</v-card-title>

      <v-card-text v-if="text !== null" class=" text-center px-0 pb-0">
        <v-col>{{ text }}</v-col>
      </v-card-text>
      <v-card-text v-if="subText !== null" class="text-center px-0 pb-0"
        ><v-col>{{ subText }}</v-col></v-card-text
      >
      <v-card-text
        v-if="multiLineText !== null"
        class="text-center py-0 py-3 mx-auto"
      >
        <v-col
          v-for="(text, index) in splitMessageLine(multiLineText)"
          :key="index"
          :class="isLineBreakStyle(text)"
          >{{ text }}</v-col
        >
      </v-card-text>

      <v-container class="px-8">
        <ValidationObserver ref="observer">
          <v-form @submit.prevent>
            <!-- 編集項目 -->
            <v-row v-for="(item, index) in targets" :key="index">
              <v-col>
                <div class="mt-2 text-center" v-if="item.subheader">
                  {{ item.subheader }}
                </div>
                <template v-if="item.type == 'text'">
                  <ValidationProvider
                    :rules="item.rule"
                    immediate
                    v-slot="{ errors }"
                  >
                    <v-text-field
                      v-model="item.value"
                      :label="item.label"
                      dense
                      hide-details
                      @change="item.function ? item.function(item) : null"
                    ></v-text-field>
                    <ValidationError class="ma-0" :error="errors[0]" />
                  </ValidationProvider>
                </template>
                <template v-if="item.type == 'phone'">
                  <ValidationProvider
                    :rules="item.rule"
                    immediate
                    v-slot="{ errors }"
                  >
                    <v-text-field
                      v-model="item.value"
                      :label="item.label"
                      dense
                      hide-details
                      @change="item.function ? item.function(item) : null"
                      @keyup.enter="onConvertToHalfWidth(item)"
                      @blur="onConvertToHalfWidth(item)"
                      @compositionend="onConvertToHalfWidth(item)"
                    ></v-text-field>
                    <ValidationError class="ma-0" :error="errors[0]" />
                  </ValidationProvider>
                </template>
                <template v-if="item.type == 'textarea'">
                  <ValidationProvider
                    :rules="item.rule"
                    immediate
                    v-slot="{ errors }"
                  >
                    <v-textarea
                      v-model="item.value"
                      :label="item.label"
                      :counter="item.max"
                      @change="item.function ? item.function(item) : null"
                    ></v-textarea>
                    <ValidationError class="ma-0" :error="errors[0]" />
                  </ValidationProvider>
                </template>
                <template v-if="item.type == 'checkBox'">
                  <ValidationProvider
                    :rules="item.rule"
                    immediate
                    v-slot="{ errors }"
                  >
                    <input
                      type="checkbox"
                      :value="item.value"
                      v-model="item.value"
                      @change="item.function ? item.function(item) : null"
                    />
                    <label class="ml-1" for="checkbox">{{ item.label }}</label>
                    <ValidationError class="ma-0" :error="errors[0]" />
                  </ValidationProvider>
                </template>
                <template v-if="item.type == 'checkList'">
                  <label>{{ item.label }}</label>
                  <ValidationProvider
                    :rules="item.rule"
                    immediate
                    v-slot="{ errors }"
                  >
                    <v-row>
                      <v-col
                        v-for="(listItem, index) in item.list"
                        :key="index"
                      >
                        <div style="margin: 0 auto;">
                          <input
                            type="checkbox"
                            :id="listItem.text"
                            :value="listItem.value"
                            v-model="item.value"
                            @change="item.function ? item.function(item) : null"
                          />
                          <label :for="listItem.text" class="ml-3">{{
                            listItem.text
                          }}</label>
                        </div>
                      </v-col>
                    </v-row>
                    <ValidationError class="ma-0" :error="errors[0]" />
                  </ValidationProvider>
                </template>
                <template v-if="item.type == 'select'">
                  <ValidationProvider
                    :rules="item.rule"
                    immediate
                    v-slot="{ errors }"
                  >
                    <v-select
                      v-model="item.value"
                      :label="item.label"
                      item-text="text"
                      item-value="value"
                      :items="item.list"
                      :value="item.value"
                      dense
                      hide-details
                      @change="item.function ? item.function(item) : null"
                    />
                    <ValidationError class="ma-0" :error="errors[0]" />
                  </ValidationProvider>
                </template>
                <template v-if="item.type == 'radio'">
                  <label>{{ item.label }}</label>
                  <ValidationProvider
                    :rules="item.rule"
                    immediate
                    v-slot="{ errors }"
                  >
                    <v-row>
                      <v-col
                        v-for="(listItem, index) in item.list"
                        :key="index"
                        style="margin: 0 auto;"
                      >
                        <input
                          type="radio"
                          :id="listItem.text"
                          :value="listItem.value"
                          v-model="item.value"
                          @change="item.function ? item.function(item) : null"
                        />
                        <label :for="listItem.text" class="ml-3">{{
                          listItem.text
                        }}</label>
                      </v-col>
                    </v-row>
                    <ValidationError class="ma-0" :error="errors[0]" />
                  </ValidationProvider>
                </template>
                <template v-if="item.type == 'date'">
                  <v-menu
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    width="100%"
                    max-width="350px"
                    v-model="menu"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <ValidationProvider
                        :rules="item.rule"
                        immediate
                        v-slot="{ errors }"
                      >
                        <v-text-field
                          v-model="item.value"
                          :label="item.label"
                          prepend-icon="event"
                          readonly
                          clearable
                          v-bind="attrs"
                          v-on="on"
                          dense
                          hide-details
                        ></v-text-field>
                        <ValidationError class="ma-0" :error="errors[0]" />
                      </ValidationProvider>
                    </template>
                    <v-date-picker
                      locale="jp-ja"
                      v-model="item.value"
                      :day-format="date => new Date(date).getDate()"
                      color="calendar"
                      full-width
                      @change="menu = false"
                    ></v-date-picker>
                  </v-menu>
                </template>
                <!-- 
                  ファイル選択状況初期化
                  ダイアログ表示時、常にファイル未選択とするためダイアログ表示に合わせてフォームを再生成とする
                -->
                <template v-if="item.type == 'file' && showDialog">
                  <ValidationProvider
                    :rules="item.rule"
                    v-slot="{ validate, errors }"
                  >
                    <input
                      type="file"
                      @change="callbackSetFile(validate, item, $event)"
                      :accept="item.accept"
                      style="max-width:100%"
                    />
                    <ValidationError class="ma-0" :error="errors[0]" />
                    <p class="mb-0">{{ item.limitFileSizeText }}</p>
                    <p class="mb-0">{{ item.acceptText }}</p>
                  </ValidationProvider>
                </template>
                <p v-if="item.text">{{ item.text }}</p>
              </v-col>
            </v-row>
          </v-form>
        </ValidationObserver>
      </v-container>

      <v-card-actions class="justify-center pb-5">
        <template v-if="negativeButtonTitle !== null">
          <v-btn
            class="title mr-4"
            color="back"
            width="33%"
            max-width="170px"
            style="font-size:15px !important"
            dark
            @click="beforeNegativeButton"
            >{{ negativeButtonTitle }}</v-btn
          >
        </template>
        <v-btn
          class="title"
          color="next"
          width="33%"
          max-width="170px"
          style="font-size:15px !important"
          :dark="!disabled"
          :disabled="disabled"
          @click="beforePositiveButton(targets)"
          >{{ positiveButtonTitle }}</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import ValidationError from '@/components/organisms/common/ValidationError';
import '@/lib/veeValidation';
import { splitMessageLine } from '@/lib/commonUtil';

export default {
  name: 'Dialog',
  components: {
    ValidationProvider,
    ValidationObserver,
    ValidationError,
  },
  props: {
    maxWidth: Number,
    title: String,
    text: String,
    subText: String,
    multiLineText: {
      default: null,
      type: String,
    },
    negativeButtonTitle: String,
    positiveButtonTitle: String,
    onClickNegativeButton: Function,
    onClickPositiveButton: Function,
    showDialog: Boolean,
    disabled: Boolean,
    targets: Array,
    /** 
    targets: [{
        value: null, // フォーム初期値
        targetName: '', // テーブル項目名、編集時のvalueの特定に使用
        type: '', // フォームタイプ[text,textarea,checkbox,select,file,date]
        limitFileSizeText: '', // アップロード可能なファイルサイズに関する説明
        accept: '', // フォームの制限
        acceptText: '', // フォームの制限に関する説明
        label: null, // フォームのラベル
        text: null, // フォームの補足説明
        rule: 'required|max:100', // バリデーション ルール
        key: '', // レスポンス時のkey,
        listName: '', // 非必須要素、リストが必要なフォームの場合にリスト名を指定
        list: '', // 非必須要素、リスト名を元に配列情報を取得
        function: Function, // 非必須要素、チェンジイベントとして発火
        max: '' // カウンター表示最大文字数
      }]
    */
  },
  data() {
    return {
      resetFlg: true,
      menu: false,
      breakLineStyle: ['py-3'],
      noBreakLineStyle: ['py-0'],
    };
  },
  mounted() {
    this.$store.subscribeAction({
      after: () => {
        if (this.$refs.observer) this.$refs.observer.validate();
      },
    });
  },
  methods: {
    onConvertToHalfWidth(item) {
      // 全角英数字を半角英数字に自動変換
      if (item.value)
        item.value = item.value.replace(/[０-９]/g, function(s) {
          return String.fromCharCode(s.charCodeAt(0) - 65248);
        });
    },
    save(date) {
      this.$refs.menu[0].save(date);
      // 再入力に備えて、入力が終わったら同期する
      this.pickerDate = date;
    },
    beforeNegativeButton() {
      this.resetFlg = true;
      this.onClickNegativeButton();
    },
    async beforePositiveButton() {
      if (await this.$refs.observer.validate()) {
        const response = {};
        // key : valueの形式に整形
        await this.targets.map(target => {
          response[target.key] = target.value;
          // 複数のチェックボックスの場合の整形
          if (target.checkList && target.checkList.length > 0) {
            target.checkList.map(checkbox => {
              response[checkbox.key] = checkbox.value;
            });
          }
        });
        this.resetFlg = true;
        this.onClickPositiveButton(response);
      }
    },
    callbackSetFile(validate, item, event) {
      validate(event);
      this.setFile(item, event);
      if (item.function) item.function(item);
    },
    setFile(item, event) {
      item.value = event.target.files[0];
    },
    // メッセージを改行で表示
    splitMessageLine(message) {
      return splitMessageLine(message);
    },
    isLineBreakStyle(message) {
      return message ? this.noBreakLineStyle : this.breakLineStyle;
    },
  },
  updated() {
    // 初期表示のバリデーションエラーを解除
    if (this.resetFlg && this.showDialog) {
      this.$refs.observer.reset();
      this.resetFlg = false;
    }
  },
};
</script>
<style>
.v-dialog {
  overflow-x: hidden !important;
}
.v-date-picker-table .v-btn__content {
  font-size: 25px !important;
}
.col {
  color: rgba(0, 0, 0, 0.87) !important;
  font-size: 16px !important;
}
</style>
